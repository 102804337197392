<template>
  <div>
    <!-- <BaseLay :list="rewardDetail"></BaseLay> -->
    <CardItemContainer 
      :items="rewardDetail.map(detail => ({ ...detail, subtitle: 'Use this discount code on your next order!' }))" 
      title="Your Rewards"
      class="rewards-detail"
    >
      <template v-slot:left="slotProps">
        <div class="coupon-list" :style="{'backgroundColor':slotProps.item.styles.backgroundColor}">
          <div class="coupon-left" :style="{'border-right':'1.5px dashed '+slotProps.item.styles.color }">{{slotProps.item.currency}}</div>
          <div class="coupon-right">
            <div class="coupon-right-tit">{{slotProps.item.itemTit}}</div>
            <div class="coupon-right-subtitle">{{slotProps.item.itemSubtit}}</div>
          </div>
        </div>
      </template>
      <template v-slot:default="slotProps">
        <ComInput :value="slotProps.item.discountCode"></ComInput>
      </template>
    </CardItemContainer>
  </div>
</template>

<script>
import ComInput from "../components/ComInput";
import CardItemContainer from '../components/CardItemContainer.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  mounted() {
    this.setSpinLoading(false);
  },
  computed:{
    ...mapGetters({
      rewardDetail: "rewardDetail",
    }),
  },
  methods: {
    ...mapActions({
      setSpinLoading: "spinLoading",
    }),
  },
  components: {
    ComInput,
    CardItemContainer,
  },
}
</script>

<style scoped>
.rewards-detail {
  height: 445px;
}
.rewards-detail ::v-deep(.container-list) {
  padding-top: 0px;
  border: none;
  flex-direction: column;
  background-color: #fff !important;
}
.rewards-detail ::v-deep(.coupon-list) {
  background-color: #F6F0FF;
  /* color: inherit; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  width: 100%;
  border-radius: 9px;
}
.rewards-detail ::v-deep(.coupon-left) {
  border-right: 1.5px dashed #8E3DFFFF;
  font-size: 32px;
  font-weight: bold;
  padding-right: 20px;
  color: #8E3DFFFF;
  color: inherit;
}
.rewards-detail ::v-deep(.coupon-right .coupon-right-tit) {
  padding-left: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #8E3DFFFF;
  color: inherit;
}
.rewards-detail ::v-deep(.coupon-right .coupon-right-subtitle) {
  padding-left: 20px;
  padding-top: 5px;
  font-size: 11px;
  opacity: 0.7;
  color: #8E3DFFFF;
  color: inherit;
}
.rewards-detail ::v-deep(.container-center) {
  margin-top: 26px;
}
.rewards-detail ::v-deep(.container-center .sub-tit) {
  width: 100%;
  color: #232323;
  font-size: 12px;
}
.rewards-detail ::v-deep(.container-right) {
  margin-top: 6px;
  width: 100%;
}
</style>
